export function uniqueValues<T>(values: T[]): T[] {
	if (!values?.length) {
		return [];
	}

	const unique: Record<string, T> = {};
	for (const value of values) {
		const jsonValue = JSON.stringify(value);
		unique[jsonValue] = value;
	}

	return Object.values(unique);
}
