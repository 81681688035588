export class CustomError extends Error {
	public code: string;
	public expected: boolean;

	constructor(code: string, message: string, cause?: Error, expected = false) {
		super(message, { cause: cause });

		this.code = code || 'ERROR_UNEXPECTED';
		this.expected = Boolean(expected);
	}
}
