import isNumeric from 'validator/es/lib/isNumeric';
import { ValidationError } from '../errors/ValidationError';
import { validateString } from './validateString';

export function validatePremiseNumber(premiseNumber: string): string {
	const value = validateString('Premise Id', premiseNumber, 10, 10);

	// Make sure it's a numeric value and exactly 10 digits
	if (!isNumeric(value)) {
		throw new ValidationError('Invalid Premise Id: Premise Id must be numeric');
	}

	return value;
}

export function isValidPremiseNumber(premiseNumber: string): boolean {
	try {
		validatePremiseNumber(premiseNumber);
		return true;
	} catch {
		return false;
	}
}
