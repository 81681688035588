'use client';

import { EligibilityResult } from '@dte/otw/azure-functions/src/common/interfaces/api/EligibilityResult';
import type { PremiseDataDocument } from '@dte/otw/azure-functions/src/common/interfaces/containers/premises/PremiseData';
import { getPremiseEligibilityResult } from '@dte/otw/azure-functions/src/common/premises/data/attributes/eligibility/getPremiseEligibilityResult';
import { EmergencyDisconnectReason } from '@dte/otw/azure-functions/src/common/services/emergencyDisconnect/EmergencyDisconnectReason';
import { filterEmpty } from '@dte/otw/azure-functions/src/common/util/lists/filterEmpty';
import { addSearchParamsToUrl } from '@dte/otw/azure-functions/src/common/util/network/url/addSearchParamsToUrl';
import { isEmpty } from '@dte/otw/azure-functions/src/common/util/objects/isEmpty';
import { equalsIgnoreCase } from '@dte/otw/azure-functions/src/common/util/strings/equalsIgnoreCase';
import { type PropsWithChildren, type ReactNode, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useJsonEqualState } from '../../../../../../hooks/useJsonEqualState';
import AuthenticatedJsonContent, { FetchJsonContent } from '../../../content/jsonGet/AuthenticatedJsonContent';
import { getPremiseMasterDataURL } from '../status/getPremiseMasterDataURL';
import type { PropsWithPremiseId } from '../usePremiseId';
import styles from './PremiseEligibility.module.scss';

export const EligibilityMessages: Record<EligibilityResult, string> = {
	[EligibilityResult.ElectricActive]: 'Electric service is active',
	[EligibilityResult.DNP]: 'Premise is in DNP state',
	[EligibilityResult.ElectricInactive]: 'Electric service is inactive',
	[EligibilityResult.GasOnly]: 'Premise has only gas service',
	[EligibilityResult.NoService]: 'Premise has no metered service',
	[EligibilityResult.NotFound]: 'Premise not found',
};

function getUtilitySearchUrl(city: string): string {
	if (!city) {
		return undefined;
	}

	let url = 'https://utilitysearch.apps.lara.state.mi.us/api/search';
	url = addSearchParamsToUrl(url, {
		searchTerm: city,
		searchCounties: 'false',
		searchCities: 'true',
		searchTownships: 'true',
	});

	return url;
}

interface UtilityInfo {
	county: string;
	cityOrTownship: string;
	type: string;
	electric: string;
	gas: string;
	electricUrlTag: string;
	gasUrlTag: string;
}

interface UtilitySearchParams {
	city: string;
}

function UtilityDetail({ utility }: { utility: UtilityInfo }) {
	let link = 'https://www.michigan.gov/mpsc/consumer/electricity/electric-utility-addresses-contacts';

	const electricUtilityTag = utility?.electricUrlTag;
	if (!electricUtilityTag) {
		return <a href={link}>Contact Information for other electric utilities</a>;
	}

	if (equalsIgnoreCase('#Detroit_Edison', electricUtilityTag)) {
		// TODO: automate this somehow
		return (
			<>
				<p>
					DTE provides electric service within <b>{utility?.cityOrTownship}</b> -- check if there is another
					premise with electric service at or near the address
				</p>
			</>
		);
	}

	link += electricUtilityTag;

	const providerName = utility?.electric || 'electric provider';
	return (
		<>
			<p>
				If the customer is having issues with power, they should call the utility that provides their
				electricity.
			</p>
			<p>
				<a href={link}>Contact Information for {providerName}</a>
			</p>
		</>
	);
}

const UnknownProvider = (
	<>
		<p>
			If the customer is having issues with power, they should call the utility that provides their electricity.
		</p>
		<p>
			<a href="https://utilitysearch.apps.lara.state.mi.us/search">Look up the customer's electric provider</a>
		</p>
	</>
);

function UtilitySearchResult({ city }: UtilitySearchParams) {
	const url = getUtilitySearchUrl(city);

	const [utilities, setUtilities] = useJsonEqualState<UtilityInfo[]>();

	// Filter to things that have an electric provider listed
	const electricUtilities = useMemo(() => {
		let filtered = filterEmpty(utilities);
		filtered = filtered.filter((utility) => utility.electricUrlTag);
		return filtered;
	}, [utilities]);

	let providerDetails = null;
	// No potential matches for electric utilities found
	if (!city || isEmpty(electricUtilities)) {
		providerDetails = UnknownProvider;
	} else {
		providerDetails = <UtilityDetail utility={electricUtilities[0]} />;
	}

	return (
		<>
			<FetchJsonContent url={url} setData={setUtilities}>
				{providerDetails}
			</FetchJsonContent>
		</>
	);
}

function ErrorMessage(props: PropsWithChildren) {
	return (
		<Container className={styles.error}>
			<Row>
				<Col>{props.children}</Col>
			</Row>
		</Container>
	);
}

export default function PremiseEligibility(props: PropsWithPremiseId): ReactNode {
	const premiseId = props.premiseId;

	const url = getPremiseMasterDataURL(premiseId);
	const [masterData, setMasterData] = useState<PremiseDataDocument>();

	let content: ReactNode = null;

	// Premise is disconnected
	const emergencyDisconnect = masterData?.data?.reason === EmergencyDisconnectReason;
	if (emergencyDisconnect) {
		content = (
			<>
				<ErrorMessage>Emergency disconnect requested</ErrorMessage>
			</>
		);
	} else {
		// Got eligibility data
		const code = getPremiseEligibilityResult(masterData);

		if (code !== EligibilityResult.ElectricActive) {
			const message = EligibilityMessages[code];

			// Add utility lookup if no electric service
			const noElectric = [EligibilityResult.NoService, EligibilityResult.GasOnly].includes(code);
			if (noElectric) {
				const city = masterData?.data?.address?.city;
				content = (
					<ErrorMessage>
						<p>{message}</p>
						<UtilitySearchResult city={city} />
					</ErrorMessage>
				);
			}
			// Just show the message
			else if (message) {
				content = <ErrorMessage>{message}</ErrorMessage>;
			}
		}
	}

	return (
		<AuthenticatedJsonContent url={url} setData={setMasterData} hasData={Boolean(masterData?.data)}>
			{content}
		</AuthenticatedJsonContent>
	);
}
