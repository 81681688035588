import type { PremiseDataAddress } from '../../../../interfaces/containers/premises/PremiseData';
import { safeJoin } from '../../../../util/strings/safeJoin';
import { titleCaseValue } from '../../../../util/strings/titleCaseValue';

export function getAddressLine1(address: PremiseDataAddress): string {
	const parts = [
		address.houseNumber,
		address.preDirection,
		address.streetName,
		address.streetSufix,
		address.postDirection,
	];

	let joined = safeJoin(parts);
	joined = titleCaseValue(joined);

	return joined;
}
