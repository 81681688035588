import { filterEmpty } from '../lists/filterEmpty';
import { getArray } from '../lists/mergeArrays';
import { normalizeValue } from './normalizeValue';

export function normalizeValues<T extends string>(values: T | T[]): T[] {
	let normalized = getArray<T>(values);

	// Normalize all the values
	normalized = normalized.map(normalizeValue);

	// Remove any values that are empty
	return filterEmpty(normalized);
}
