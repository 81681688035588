import { normalizeValue } from '../../strings/normalizeValue';

export function addSearchParam(searchParams: URLSearchParams, key: string, value: string): URLSearchParams {
	// TODO: should we be using lowercase everywhere for keys?
	const normalizedKey = normalizeValue(key);
	if (!normalizedKey) {
		return searchParams;
	}
	const normalizedValue = normalizeValue(value);
	if (!normalizedValue) {
		return searchParams;
	}

	const newSearchParams = new URLSearchParams(searchParams);
	newSearchParams.set(normalizedKey, normalizedValue);

	return newSearchParams;
}

const dummyDomain = 'http://example.com';

export function addSearchParamsToUrl(
	originalUrl: string,
	params: URLSearchParams | Record<string, string> = {},
): string {
	const normalized = normalizeValue(originalUrl);
	if (!normalized) {
		return undefined;
	}

	const relativeUrl = normalized.startsWith('/');

	let url: URL;
	try {
		if (relativeUrl) {
			// Handle relative URLs by providing a base URL
			url = new URL(normalized, dummyDomain);
		} else {
			url = new URL(normalized);
		}
	} catch {
		return undefined;
	}

	// Include existing parameters
	let searchParams = new URLSearchParams(url.search);

	const newParams = new URLSearchParams(params);

	// Append new parameters to existing ones
	for (const [key, value] of newParams) {
		searchParams = addSearchParam(searchParams, key, value);
	}

	// Set the updated search parameters back to the URL object
	url.search = searchParams.toString();

	// Return the pathname and search part of the URL to handle relative URLs correctly

	let updatedUrl = url.toString();
	if (relativeUrl) {
		updatedUrl = updatedUrl.substring(dummyDomain.length);
	}

	return updatedUrl;
}

export function addSearchParamToUrl(originalUrl: string, key: string, value: string): string {
	const params = {};
	params[key] = value;
	return addSearchParamsToUrl(originalUrl, params);
}
