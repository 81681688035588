'use client';

import type { PropsWithChildren, ReactNode } from 'react';
import ConditionalLink from '../../../jobs/(jobId)/jobLink/ConditionalLink';
import type { PropsWithPremiseId } from '../usePremiseId';

interface PremiseLinkProps extends PropsWithPremiseId, PropsWithChildren {}

export function PremiseLink(props: PremiseLinkProps): ReactNode {
	const premiseId = props.premiseId;
	if (!premiseId) {
		return null;
	}

	const link = `/premises/?premiseId=${premiseId}`;

	return <ConditionalLink href={link}>{props.children || premiseId}</ConditionalLink>;
}
