import { EligibilityResult } from '../../../../interfaces/api/EligibilityResult';
import type { PremiseDataDocument } from '../../../../interfaces/containers/premises/PremiseData';

function isDNP(masterData: PremiseDataDocument): boolean {
	return Boolean(masterData?.data?.service?.electric?.dnp);
}

export function hasGasService(masterData: PremiseDataDocument): boolean {
	return masterData.data?.service?.gas?.active !== undefined;
}

export function hasElectricService(masterData: PremiseDataDocument): boolean {
	return masterData.data?.service?.electric?.active !== undefined;
}

/**
 *
 * @param premiseData
 * @returns
 */
function getElectricResults(masterData: PremiseDataDocument): EligibilityResult {
	const electricActive = masterData.data?.service?.electric?.active;
	if (electricActive === undefined) {
		return undefined;
	}

	// Service is active
	if (electricActive) {
		return EligibilityResult.ElectricActive;
	}

	// Service is inactive due to DNP
	const dnp = isDNP(masterData);
	if (dnp) {
		return EligibilityResult.DNP;
	}

	// Service is inactive for other reasons
	return EligibilityResult.ElectricInactive;
}

function getGasResults(masterData: PremiseDataDocument): EligibilityResult {
	const gasActive = masterData.data?.service?.gas?.active;
	if (gasActive === undefined) {
		return undefined;
	}

	// TODO: should we differentiate between active vs. inactive for gas?

	return EligibilityResult.GasOnly;
}

export function getPremiseEligibilityResult(masterData: PremiseDataDocument): EligibilityResult {
	if (!masterData) {
		return undefined;
	}

	// If the record has been flagged as deleted, consider that as no service
	if (masterData.isDeleted) {
		return EligibilityResult.NoService;
	}

	let result = getElectricResults(masterData);
	if (result) {
		return result;
	}

	result = getGasResults(masterData);
	if (result) {
		return result;
	}

	// Fallback if we don't match other criteria
	return EligibilityResult.NoService;
}
